import React, { Component } from 'react'
import styled from 'styled-components'
import { dimens, colors } from '../constants'
import ColorText from './ColorText'

const cardWidth = '50%'
const cardHeight = '175px'
//const cardWidthHover = cardWidth * 1.35

const ImageWrapper = styled.div`
    width: 50px;
    height:  50px;
    min-width: 50px;
    min-height: 50px;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;

    @media (min-width: ${dimens.widthLargeDevice}px) {
        width: 75px;
        height:  75px;
    }
`

const Text = styled.p`
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    text-align: justify;
    color: ${colors.textColorSoft};
    transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.4s ease-in-out;
    cursor: default;

    @media (min-width: ${dimens.widthLargeDevice}px) {
        font-size: 18px;
    }
`

const SubjectContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const Subject = styled(ColorText)`
    margin: 0;
    margin-top: ${dimens.paddingSmall}px;
    margin-right: ${dimens.paddingSmall}px;
    color: ${colors.textColorSofter};
    font-size: 12px;
    border-radius: 20px;
    border: 1px solid lightgray;
    padding: 4px 8px;
    filter: brightness(0.9);
    cursor: default;

    @media (min-width: ${dimens.widthLargeDevice}px) {
        font-size: 14px;
        margin-right: ${dimens.paddingMedium}px;
    }
`

// const CycledText = styled.p`
//     position: absolute;
//     width: 100%
//     margin: 0;
//     text-align: center;
//     color: ${colors.textColor};
//     opacity: 0;
//     transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.4s ease-in-out;
//     transform: translateX(${cardWidth * 1.35}px);
//     cursor: default;
// `

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${cardHeight};
    border-radius: ${dimens.cardRadius}px;
    border: 1px solid ${colors.cardBorder};
    background-color: ${colors.background};
    padding: max(${dimens.paddingMedium}px, min(1vw, ${dimens.paddingLarge}px));
    margin: ${dimens.paddingLarge}px;
    margin-left: 0;
    margin-top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    /* box-shadow: 3px 3px 6px rgba(0,0,0,0.2); */
    transition: box-shadow 0.2s ease-out, width 0.2s ease-in-out, height 0.2s ease-in-out;
    &:hover {
        box-shadow: 0px 2px 4px rgba(0,0,0,0.05);
    }
    &:hover ${ImageWrapper}{
        transform: scale(1.1);
    }
    &:hover ${Text} {
        color: ${colors.textColor};
    }

    @media (max-width: ${dimens.widthSmallDevice}px) {
        margin: 0px;
        margin-bottom: ${dimens.paddingLarge}px;
        height: unset;
        /* min-height: ${cardHeight}; */
    }
    
    @media (min-width: ${dimens.widthLargeDevice}px) {
        height: calc(${cardHeight} + 50px);
    }
`

const CardRow = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const CardRowBody = styled(CardRow)`
    flex: 1;
    margin-top: max(${dimens.paddingSmall}px, min(0.5vw, ${dimens.paddingLarge}px));
`;

const Image = styled.img`
    width: 150px;
    height: 150px;
`

class ContentCard extends Component {

    render() {
        const { data } = this.props
        const { boldCount=1 } = data
        let getImage = data.getImage || (() => {})
        let splitText = data.text.split(' ')
        let boldedText = []
        let i = 0;
        for (; i < boldCount; i++) {
            if(i >= splitText.length)
                break

            boldedText.push(splitText[i])
        }
        splitText.splice(0, i)

        const { subjects=[] } = data

        return (
            <CardWrapper >
                <CardRow style={{justifyContent: "center", flexShrink: 0, minHeight: '50px' }}>
                    <ImageWrapper style= {{ backgroundColor: 'transparent' }}>
                        {getImage()}
                    </ImageWrapper>
                </CardRow>
                <CardRowBody>
                    <Text><strong>{boldedText.join(' ') + ' '}</strong>{splitText.join(' ')}</Text>
                </CardRowBody>
                <CardRow style={{ }}>
                    <SubjectContainer>
                        {subjects.map((subject, index) => (
                            <Subject key={`${index}_${subject}`} animate={false}>
                                {subject}
                            </Subject>
                        ))}
                    </SubjectContainer>
                </CardRow>
            </CardWrapper>
        )
    }
}

export default ContentCard