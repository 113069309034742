import React, { Component } from 'react';
import styled from 'styled-components';
import { colors, dimens } from '../constants';
import {
    MdHome,
    MdAssessment,
    MdDeveloperBoard,
    MdPerson,
    MdDescription,
    MdOpenInNew,
} from 'react-icons/md';
import { FcHome, FcBusinessman, FcCalendar } from 'react-icons/fc';
import ProfilePicture from '../media/Portrait.jpg';

const drawerMin = 72;
const drawerMax = '250px';
const animationTime = 0.2;
const iconSize = 24;
const iconScale = 0.9;
const iconScaleUp = iconScale + 0.1;

const DrawerPlaceHolder = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: width ${animationTime}s ease-in-out;
    overflow-x: hidden;
    overflow-y: hidden;
`;

const DrawerWrapper = styled.div`
    height: 100vh;
    position: fixed;
    z-index: 10;
    display: flex;
    flex-direction: column;
    transition: width ${animationTime}s ease-in-out;
    overflow-x: hidden;
    overflow-y: hidden;
    color: ${colors.drawerTextColor};
    background-color: ${colors.drawer};
    /* background-image: ${colors.drawerGradient}; */
`;

const HeaderWrapper = styled.div`
    width: 100%;
    height: 30vh;
    max-height: 250px;
    flex-shrink: 0;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const HeaderProfilePicture = styled.img`
    width: 15vw;
    height: 15vw;
    max-width: min(185px, calc(26vh - ${dimens.paddingXLarge}px));
    max-height: min(185px, calc(26vh - ${dimens.paddingXLarge}px));
    min-width: 145px;
    min-height: 145px;
    border-radius: 50%;
    object-fit: cover;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    border: 2px solid ${colors.drawerTextColor};
    margin-top: ${dimens.paddingXLarge}px;
`;

const HeaderDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.drawerTextColor};
    margin-left: ${dimens.paddingLarge + 'px'};
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-left: ${dimens.paddingLarge + 'px'};
    margin-bottom: ${dimens.paddingXLarge}px;

    @media (min-height: 700px) {
        height: 320px;
    }
`;

const ListItemText = styled.span`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    font-size: 18px;
    width: 100%;
    user-select: none;
    margin-left: ${iconSize + dimens.paddingLarge}px;
    letter-spacing: 1px;
    transition: letter-spacing 0.2s ease-out;
`;

const ListItemIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    margin-left: ${dimens.paddingSmall}px;
    margin-right: ${dimens.paddingLarge}px;
    font-size: ${iconSize}px;
    transition: all 0.2s ease-out;
    color: ${colors.drawerTextColor};
    transform: scale(${iconScale});
    /* filter: drop-shadow(1px 1px 0 white)
        drop-shadow(-1px 1px 0 white)
        drop-shadow(1px -1px 0 white)
        brightness(0)
        invert(1); */
`;

const ListItemHref = styled(MdOpenInNew)`
    position: absolute;
    right: ${dimens.paddingLarge}px;
    font-size: 20px;
`;

const ListItemWrapper = styled.li`
    position: relative;
    height: 10vh;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &:hover {
        background-color: ${colors.genericHighlight};
    }
    &:hover > ${ListItemText} {
        /* padding-left: ${dimens.paddingSmall + 'px'}; */
        letter-spacing: 2px;
    }
    &:hover > ${ListItemIcon} {
        /* padding-left: ${dimens.paddingSmall + 'px'}; */
        transform: scale(${iconScaleUp});
        /* opacity: 1; */
    }
    &:active {
        background-color: ${colors.genericPressed};
    }
`;

const CreditText = styled.h5`
    font-weight: normal;
    font-size: 10px;
    margin: ${dimens.paddingMedium}px;
    margin-top: auto;
    margin-left: ${dimens.paddingLarge}px;
    cursor: default;
    user-select: none;
    flex-wrap: nowrap;
    white-space: nowrap;
`;

const ScrollingSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
`;

const CollapsedIconContainer = styled.div`
    display: flex;
    flex-direction: column;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    flex: 1;
`;
const CollapsedIconWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: max(${drawerMin}px, 6vw);
    max-width: 100px;
    overflow: auto;
    background-color: transparent;
    z-index: 10;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    margin-top: ${dimens.paddingLarge * 2 + 48}px;
    max-height: calc(100vh - ${dimens.paddingLarge * 2 + 48}px);
`;

const CollapsedIcon = styled.div`
    height: 13vh;
    min-height: 60px;
    max-height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &:hover {
        background-color: ${colors.genericHighlight};
    }
    &:hover > ${ListItemText} {
        /* padding-left: ${dimens.paddingSmall + 'px'}; */
        letter-spacing: 2px;
    }
    &:hover > ${ListItemIcon} {
        /* padding-left: ${dimens.paddingSmall + 'px'}; */
        transform: scale(${iconScaleUp});
    }
    &:active {
        background-color: ${colors.genericPressed};
    }

    svg {
        font-size: max(24px, min(28px, 2vw));
    }
`;

const listItems = [
    {
        name: 'Home',
        getIcon: () => <MdHome style={{ color: 'inherit' }} />,
    },
    {
        name: 'Experience',
        getIcon: () => <MdAssessment style={{ color: 'inherit' }} />,
    },
    {
        name: 'Projects',
        getIcon: () => <MdDeveloperBoard style={{ color: 'inherit' }} />,
    },
    {
        name: 'Contact',
        getIcon: () => <MdPerson style={{ color: 'inherit' }} />,
    },
    {
        name: 'Resume',
        getIcon: () => <MdDescription style={{ color: 'inherit' }} />,
    },
];

class Drawer extends Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
    }

    getWidth = () => {
        return this.wrapperRef.current.getBoundingClientRect().width;
    };

    render() {
        const { isOpen, isMinified, onItemClicked = () => {} } = this.props;
        const openDrawerWidth = 'max(230px, min(22vw, 300px))';
        const closedDrawerWidth = `min(100px, max(${drawerMin}px, 6vw))`;

        return (
            <>
                <DrawerPlaceHolder
                    style={{
                        // minWidth: isMinified ? '0px' : isOpen ? '200px': `${drawerMin}px`,
                        width: isMinified
                            ? '0px'
                            : isOpen
                            ? openDrawerWidth
                            : `${closedDrawerWidth}`,
                    }}
                />
                <DrawerWrapper
                    ref={this.wrapperRef}
                    style={{
                        // minWidth: isOpen ? '200px': isMinified ? '0px' : `${drawerMin}px`,
                        width: isOpen
                            ? openDrawerWidth
                            : isMinified
                            ? '0px'
                            : `${closedDrawerWidth}`,
                    }}
                >
                    {!isMinified && (
                        <CollapsedIconWrapper
                            style={{
                                pointerEvents: isOpen ? 'none' : 'all',
                                opacity: isOpen ? 0 : 1,
                                transform: `translateY(${isOpen ? 100 : 0}px)`,
                            }}
                        >
                            <CollapsedIconContainer>
                                {listItems.map((item, index) => {
                                    const {
                                        name,
                                        href = null,
                                        getIcon = () => {},
                                    } = item;
                                    return (
                                        <CollapsedIcon
                                            key={`Collapsed_${name}_${index}`}
                                            title={name}
                                            onClick={() =>
                                                onItemClicked(index, item)
                                            }
                                            alt={name}
                                        >
                                            {getIcon && (
                                                <ListItemIcon
                                                    style={{ margin: 0 }}
                                                >
                                                    {getIcon()}
                                                </ListItemIcon>
                                            )}
                                        </CollapsedIcon>
                                    );
                                })}
                            </CollapsedIconContainer>
                        </CollapsedIconWrapper>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            flexDirection: 'column',
                            transition: `transform ${animationTime}s ease-in-out, opacity ${animationTime}s ease-in-out`,
                            transform: isOpen ? '' : 'translateX(-350px)',
                            width: '100%',
                            opacity: isOpen ? 1 : 0,
                        }}
                    >
                        <HeaderWrapper>
                            <HeaderProfilePicture src={ProfilePicture} />
                        </HeaderWrapper>
                        <h3
                            style={{
                                fontSize: '10px',
                                marginLeft: 'auto',
                                marginRight: dimens.paddingMedium,
                                userSelect: 'none',
                                fontStyle: 'italic',
                            }}
                        >
                            Welcome
                        </h3>
                        <HeaderDivider />
                        <ScrollingSection>
                            <List>
                                {listItems.map((item, index) => {
                                    const {
                                        name,
                                        href = null,
                                        getIcon = null,
                                    } = item;
                                    const transitionDuration =
                                        animationTime +
                                        0.2 +
                                        (isOpen ? index * 0.1 : 0);
                                    const timingFunction =
                                        'cubic-bezier(.07,.6,.27,1.6)';
                                    const transitionDelay = isOpen ? 0.08 : 0;

                                    return (
                                        <ListItemWrapper
                                            key={name + '' + index}
                                            style={{
                                                opacity: isOpen ? 1 : 0,
                                                transform: `translateX(${
                                                    isOpen ? 0 : -50
                                                }px)`,
                                                transition: `opacity ${animationTime}s ease-in-out ${transitionDelay}s, transform ${transitionDuration}s ${timingFunction} ${transitionDelay}s`,
                                            }}
                                            onClick={() =>
                                                onItemClicked(index, item)
                                            }
                                        >
                                            {getIcon && (
                                                <ListItemIcon>
                                                    {getIcon()}
                                                </ListItemIcon>
                                            )}
                                            <ListItemText
                                                style={{
                                                    borderTopRightRadius: '6px',
                                                    borderBottomRightRadius:
                                                        '6px',
                                                }}
                                            >
                                                {name}
                                            </ListItemText>
                                            {href && (
                                                <ListItemHref>
                                                    {/* Use me for links */}
                                                </ListItemHref>
                                            )}
                                        </ListItemWrapper>
                                    );
                                })}
                            </List>
                            <CreditText>
                                Chris Pavlopoulos &nbsp;&diams;&nbsp; 2024
                            </CreditText>
                        </ScrollingSection>
                    </div>
                </DrawerWrapper>
            </>
        );
    }
}

export default Drawer;
