import React, { Component } from 'react';
import styled, { css as cssTag, keyframes } from 'styled-components';
import { ColorHelper } from '../helpers';

const red = '#ff9aa2';
const salmon = '#ffb7b2';
const orange = '#ffdac1';
const green = '#e2f0cb';
const turqoise = '#b5ead7';
const purple = '#c7ceea';

const ANIMATIONS = {
    fade: 'fade',
    random: 'random',
    slideVertical: 'slideVertical',
}

const fadeIn = keyframes` {
    0% { 
        opacity: 0; 
    }
    100% { 
        opacity: 1; 
    }
  }
`;

const randomIn = (offset, direction='right') => {
    let translate

    switch (direction) {
        case 'right':
            translate = `${offset}px, 0px`
            break
        case 'left':
            translate = `-${offset}px, 0px`
            break
        case 'bottom':
            translate = `0px, -${offset}px`
            break
        case 'top':
            translate = `0px, ${offset}px`
            break
    }

    return (

        keyframes` {
            0% { 
                transform: translate(${translate});
            }

            100%  { 
                transform: translate(0px, 0px); 
            }
        }`
    )
}

const slideIn = (veritcalOffset) => keyframes` {
    0% { 
        transform: translate(0px, ${veritcalOffset}px); 
    }
    50% {
        transform: translate(0px, -${veritcalOffset / 2}px); 
    }
    100%  { 
        transform: translate(0px, 0px); 
    }
  }
`;

const float = (veritcalOffset) => keyframes` {
    0% { 
        transform: translate(0px, ${-veritcalOffset}px); 
    }
    30%  { 
        transform: translate(0px, 0px); 
    }
    100% { 
        transform: translate(0px, ${-veritcalOffset}px); 
    }
  }
`;

const SegmentWrapper = styled.span`
    position: relative;
    display:inline-block;
    ${props => props.hoverDefault};
    transition: ${props => props.hoverTransition};
    &:hover {
        ${props => props.hover}
    }
`

// Use styled.attrs to avoid creating a new css class for each value
const ColorJSX = styled.span.attrs(props => ({
    style: {
        animationDuration: `${props.duration}s`,
        animationDelay: `${props.delay}s`,
        opacity: props.opacity,
        color: props.color,
    }
}))`
    animation: ${props => props.animation};
    display: inline-block;
    animation-fill-mode: forwards;
    transition: top 1s ease-in-out, bottom 1s ease-in-out;
    &::selection {
        background: ${props => props.highlight || 'rgb(140, 140, 160)'};
    }
`

const testColors = [red, salmon, orange, green, turqoise, purple]

class ColorText extends Component {

    state = {
        randomDirections: [],
    }

    constructor (props) {
        super(props)

        let text = props.children
        if(Array.isArray(text))
            text = text.join(' ')

        let randomDirections = []
        
        for(let i = 0; i < text.length; i++) {
            let rand = Math.random()
            let direction = 'left'

            if(rand > 0.25 && rand < 0.5)
                direction = 'top'
            else if (rand > 0.5 && rand < 0.75)
                direction = 'right'
            else if (rand > 0.75)
                direction = 'bottom'
                
            randomDirections.push(direction)
        }

        this.state.randomDirections = randomDirections
    }



    interpolateColors = (fullLength, colors) => {
        //for each pair of colors... divide the length by the amount of color pairs (length / (colors.length - 1))
        const interpolation = []
        let colorCount = colors.length

        // If it's one color just push that color for each letter
        if(colorCount === 1) {
            for(let i = 0; i < fullLength; i++)
                interpolation.push(colors[0])
            
            return interpolation
        }

        if(colorCount - 1 > fullLength)
            colorCount = fullLength
        
        let length = Math.floor(fullLength / (colorCount - 1))
        for (let i = 0; i < colorCount - 1; i++) {
            const colorStart = colors[i]
            const colorEnd = colors[i + 1]
            const arrStart = ColorHelper.arrayFromColor(colorStart), arrEnd = ColorHelper.arrayFromColor(colorEnd)

            const rStep = (arrEnd[0] - arrStart[0]) / length
            const gStep = (arrEnd[1] - arrStart[1]) / length
            const bStep = (arrEnd[2] - arrStart[2]) / length
            const stepSizes = [rStep, gStep, bStep]
            const rgbVals = [arrStart[0], arrStart[1], arrStart[2]]


            // Add the start color
            interpolation.push(ColorHelper.arrayToColor(arrStart))

            // If any of our rgb values are not yet close enough to the end rgb values, continue the algorithm
            while (
                Math.abs(rgbVals[0] - arrEnd[0]) > Math.abs(stepSizes[0]) || 
                Math.abs(rgbVals[1] - arrEnd[1]) > Math.abs(stepSizes[1]) || 
                Math.abs(rgbVals[2] - arrEnd[2]) > Math.abs(stepSizes[2])
                ) {

                // We check each rgb value individually to to see if the difference between them and 
                // our goal rgb values are small enough to stop modifying them.
                //
                // e.g: rStart = 23, rEnd = 28, rStep = 2
                // r = 23, abs(23 - 28) > 2, r += 2
                // r = 25, abs(25 - 28) > 2, r += 2
                // r = 27, abs(27 - 28) < 2, STOP CHANGING THE R VALUE
                if(Math.abs(rgbVals[0] - arrEnd[0]) > Math.abs(stepSizes[0]))
                    rgbVals[0] += stepSizes[0]            
                if(Math.abs(rgbVals[1] - arrEnd[1]) > Math.abs(stepSizes[1]))
                    rgbVals[1] += stepSizes[1]            
                if(Math.abs(rgbVals[2] - arrEnd[2]) > Math.abs(stepSizes[2]))
                    rgbVals[2] += stepSizes[2]
                
                // Push this new color
                interpolation.push(ColorHelper.arrayToColor(rgbVals))
            }

            // Since the algorithm ends right before the end color, we add the end color here
            interpolation.push(ColorHelper.arrayToColor(arrEnd))
        }

        return interpolation
    }

    generateColorSpectrumArray = (length, floor=180, startArray=[255, -1, 255]) => {
        const spectrum = []
        const iterations = 3
        for (let i = 0; i < startArray.length; i++) {
            if(startArray[i] === -1)
                startArray[i] = floor
        }
        let rgbArr = startArray || [255, floor, 255]
        let rgbIndex = 0 // 0 - red, 1 - green, 2 - blue

        let isAscending = rgbArr[rgbIndex] < 255 - ((255 - floor) / 2)

        let stepSize = Math.max(1, (255 - floor) / length)
        stepSize *= iterations

        for(let i = 0; i < iterations; i ++) {

            while (isAscending ? rgbArr[rgbIndex] < 255 : rgbArr[rgbIndex] > floor){
                spectrum.push(ColorHelper.arrayToColor(rgbArr))

                rgbArr[rgbIndex] += isAscending ? stepSize : -stepSize
                rgbArr[rgbIndex] = Math.min(255, Math.max(floor, rgbArr[rgbIndex]))
            }
            rgbIndex = (rgbIndex + 1) % rgbArr.length
            isAscending = rgbArr[rgbIndex] < 255 - ((255 - floor) / 2)
        }
 

        return spectrum
    }

    render() {

        let { 
            animate, 
            enabled, 
            duration, 
            startDelay, 
            letterDelay, 
            animationOffset, 
            hover, 
            transition, 
            animation, 
            colors: colorInputArray, 
            highlight 

        } = this.props

        if(enabled !== undefined && !enabled)
            return null

            
        let text = this.props.children || '';
        if(Array.isArray(text))
            text = text.join(' ')

        duration = duration || 3
        startDelay = startDelay || 0
        letterDelay = letterDelay || 0.02
        animationOffset = animationOffset ?? 10
        animation = animation || ANIMATIONS.fade
        colorInputArray = colorInputArray && colorInputArray.length ? colorInputArray : null

        let hoverTransition = transition || ''
        let hoverDefault = {}
        if(hover) {
            for(const key in hover){
                hoverDefault[key] = '0'
                if(!transition)
                    hoverTransition += `${key} 0.2s ease-in-out`
            }
        }

        duration *= 3


        let output = [];
        let colorIndex = 0;
        let colors = colorInputArray ? this.interpolateColors(text.length, colorInputArray) : this.generateColorSpectrumArray(text.length)
        const individualDuration = duration / text.length

        for (let i = 0; i < text.length; i++) {
            let animation
            let opacity = 1
            if(animate !== undefined && !animate) {
                animation = ''
            } else {
                animation = cssTag`${randomIn(animationOffset, this.state.randomDirections[i])}, ${fadeIn}`
                opacity = 0
            }

            let char = text[i];

            const color = colors[colorIndex];
            //const highlight = colors2[colorIndex];
            const colorProps = {
                key: 'colored' + i,
                'aria-hidden': true,
                delay: startDelay + i * letterDelay,
                duration: individualDuration,
                animation,
                color,
                hover,
                hoverDefault,
                hoverTransition,
                opacity,
                highlight,
            }
            output.push(
                <SegmentWrapper {...colorProps} >
                    { char === ' ' ? (
                        <ColorJSX {...colorProps} >
                            &nbsp;
                        </ColorJSX>
                    ) : (
                        <ColorJSX {...colorProps} >
                            {char}
                        </ColorJSX>
                    )}
                </SegmentWrapper>
            );

            colorIndex = (colorIndex + 1) % colors.length;
        }

        const propStyle = this.props.style || {}
        return (
            <div aria-label={'Text'} style={{...propStyle}} className={this.props.className || ''} >
                {output}
            </div>
        )
    }
}

export default ColorText;