import React, { Component } from 'react';
import styled from 'styled-components';
import { colors, dimens } from '../constants';

const Header = styled.h1`
    margin: 0px;
    margin-top: ${dimens.paddingMedium}px;
    margin-bottom: ${dimens.paddingSmall}px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
`;
const HeaderDivider = styled.div`
    width: 60%;
    height: 2px;
    background-color: ${colors.accent};
    border-radius: 2px;
    margin-bottom: ${dimens.paddingSmall}px;
`;

class ItemHeader extends Component {
    state = {
        isHighlighted: false,
    };

    highlight = () => {};

    render() {
        let propStyle = this.props.style || {};
        return (
            <div
                style={{
                    ...propStyle,
                    display: 'inline-block',
                    flexDirection: 'column',
                    transition: 'background-color 0.2s ease-in-out',
                    paddingRight: '12px',
                    borderRadius: '6px',
                }}
            >
                <Header>{this.props.text}</Header>
                <HeaderDivider />
            </div>
        );
    }
}

export default ItemHeader;
