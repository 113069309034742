export const openPdf = (pdf) => {
    window.open(pdf);
}

export const downloadPdf = (pdf) => {
    const link = document.createElement("a");
        link.href = resumeUrl;
        link.setAttribute(
            "download",
            `Christopher Pavlopoulos Resume`
        );
        document.body.appendChild(link);
        link.click();
}
