import {
    FcPhoneAndroid,
    FcMindMap,
    FcMultipleDevices,
    FcEngineering,
    FcOldTimeCamera,
    FcCollaboration,
    FcSportsMode,
    FcSynchronize,
    FcInTransit,
    FcDocument,
    FcFlashOn,
    FcPositiveDynamic,
    FcDeployment,
} from 'react-icons/fc';

import GoogleIcon from '../media/Google__G.svg';
import IntuitIcon from '../media/Intuit_Transparent3.png';
import TurboTaxIcon from '../media/TurboTax_Transparent.png';
import MyStateIcon from '../media/MyState_Transparent.png';
import IowaStateItsIcon from '../media/Iowa_State_ITS.png';
import CernerIcon from '../media/Cerner.png';
import GmailIcon from '../media/gmail.png';
import GitHubIcon from '../media/GitHub.png';
import LinkedInIcon from '../media/LinkedIn.png';
import DozeIcon from '../media/Doze.png';

export const experiences = [
    {
        title: 'Senior Software Engineer',
        subtitle: 'Google',
        image: GoogleIcon,
        dateRange: 'Nov. 2021 - Present',
        bullets: [
            {
                boldCount: 1,
                text: 'Authored 6 architectural design documents, several of which facilitated collaboration across multiple teams.',
                getImage: () => (
                    <FcDocument
                        title="Design Docs"
                        alt="Design Docs"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['Project planning', 'Cross-team collaboration'],
            },
            {
                text: `Optimized a range of C++, Go, and SQL binaries across our stack, improving runtimes by several orders of magnitude.
                `,
                getImage: () => (
                    <FcFlashOn
                        title="Optimization"
                        alt="Optimization"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['Optimization'],
            },
            {
                text: `Excelled within a highly competitive work environment, achieving ratings within the top 20% of Google.`,
                getImage: () => (
                    <FcPositiveDynamic
                        title="Top Performance"
                        alt="Top Performance"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['Rating', 'Performance'],
            },
            {
                text: `Productionized 3 major components of our team's infrastructure, including production resource registration, ACL hierarchy & management, and on-call rotations with alerting, ensuring that each aspect adhered to best practices within the industry.`,
                getImage: () => (
                    <FcDeployment
                        title="Productionization"
                        alt="Productionization"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: [
                    'Production',
                    'Resource registration',
                    'ACL Management',
                    'Alerting',
                ],
            },
        ],
    },
    {
        title: 'Full Stack Software Engineer II - TurboTax',
        subtitle: 'Intuit',
        image: IntuitIcon,
        dateRange: 'Jul. 2020 - Nov. 2021',
        bullets: [
            {
                boldCount: 1,
                text: 'Led the development of a project to increase TurboTax year-over-year retention rate from 65% to a projected 85%.',
                getImage: () => (
                    <img
                        title="TurboTax Logo"
                        alt="TurboTax Logo"
                        src={TurboTaxIcon}
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['Lead developer', 'Project planning'],
            },
            {
                text: `Increased TurboTax to Credit Karma cross-ecosystem conversion by 18% following an A / B test on 5 different UI treatments.
              `,
                getImage: () => (
                    <FcMultipleDevices
                        title="A / B Testing"
                        alt="A / B Testing"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['A / B Testing'],
            },
            {
                text: `Investigated and discovered the cause of several high-priority issues by searching backend & frontend logs via Splunk.`,
                getImage: () => (
                    <FcMindMap
                        title="Debugging"
                        alt="Debugging"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['Debugging', 'Triaging'],
            },
            {
                text: `Engaged with modern tooling such as Jest & Cypress for testing, Docker & Kubernetes for deployment, and React & Spring for full stack development.`,
                getImage: () => (
                    <FcEngineering
                        title="Modern Tooling"
                        alt="Modern Tooling"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['Cypress', 'Docker', 'Kubernetes', 'Spring'],
            },
        ],
    },
    {
        title: 'Software Developer Intern',
        subtitle: 'Cerner',
        image: CernerIcon,
        dateRange: 'Jun. 2019 - Aug. 2019',
        bullets: [
            {
                text: "Implemented a barcode scanner into a hospital's iOS mobile app with both Objective-C and Swift.",
                getImage: () => (
                    <FcOldTimeCamera
                        alt="Barcode Scanner"
                        title="Barcode Scanner"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['Objective-C', 'Xcode', 'iOS'],
            },
            {
                text: 'Utilized modern mobile development technologies such as React & Redux.',
                getImage: () => (
                    <FcMultipleDevices
                        alt="Mobile and Web"
                        title="Mobile and Web"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['JavaScript', 'React', 'Redux'],
            },
            {
                text: 'Participated in the entire development process from Jest testing to build deployment with Jenkins.',
                getImage: () => (
                    <FcInTransit
                        alt="Software Development Cycle"
                        title="Software Development Cycle"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#e2f0cb',
                subjects: [
                    'Testing',
                    'Jest',
                    'Deployment',
                    'Jenkins',
                    'Docker',
                ],
            },
            {
                text: 'Collaborated with a team of 12, attending daily Scrums and bi-weekly sprints.',
                getImage: () => (
                    <FcCollaboration
                        alt="Collaboration"
                        title="Collaboration"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#c7ceea',
                subjects: ['Teamwork', 'Dreamwork'],
            },
        ],
    },
    {
        title: 'Mobile Application Developer',
        subtitle: 'Iowa State University ITS',
        image: IowaStateItsIcon,
        dateRange: 'Aug. 2017 - Aug. 2020',
        bullets: [
            {
                text: "Worked on Iowa State University's official Android application: MyState with over 20,000 annual users.",
                getImage: () => (
                    <img
                        title="Iowa State Logo"
                        alt="Iowa State Logo"
                        src={MyStateIcon}
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['Android', 'Java', 'Mobile'],
            },
            {
                text: "Specialized in creating custom UI elements to help improve UX. Our efforts were reflected when the app's PlayStore rating rose from 2.0 to 3.2 througout my employment at ISU ITS.",
                getImage: () => (
                    <FcPhoneAndroid
                        title="Mobile UX"
                        alt="Mobile UX"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['User Experience', 'Positive Feedback'],
            },
        ],
    },
];

export const projects = [
    {
        title: 'React Web Editor',
        titleLink: 'https://github.com/chrispavlopoulos/React-Web-Editor',
        subtitle: 'React JS, Redux, JavaScript',
        description: `A web development platform for non-developers, offering more flexibility than
      similar services by allowing you to simply modify css directly from the UI.`,
        bullets: [
            {
                text: 'Self-taught JavaScript from a Java background to build a working prototype within a month.',
                getImage: () => (
                    <img
                        title="Java to JavaScript"
                        alt="Java to JavaScript"
                        src={
                            'https://blog.livthomas.net/wp-content/uploads/2018/02/java-to-javascript.png'
                        }
                        style={{ width: '130%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['New Technologies'],
            },
            {
                text: `Awesome features such as live DOM manipulation and autocomplete / suggestions for CSS property values.`,
                getImage: () => (
                    <FcSportsMode
                        title="How Exciting"
                        alt="How Exciting"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['Ease of Use'],
            },
        ],
    },
    {
        title: 'Doze',
        titleLink: 'https://github.com/chrispavlopoulos/Doze-Android-App',
        description: `A production-ready Android app that allows you automatically reply to select contacts with a canned response when you\'re not available.
        Due to changes to Google Play Store guidelines during the development of this app, it unfortunately never saw the light of day. 
        Nevertheless, it was still an extremely fun and challenging learning experience!`,
        subtitle: 'Android',
        bullets: [
            {
                text: 'Custom UI and animations made from extending base Android classes like View and Drawable.',
                getImage: () => (
                    <img
                        title="Doze App Logo"
                        alt="Doze App Logo"
                        src={DozeIcon}
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['UI / UX'],
            },
            {
                text: 'Optimized with asyncronous batched updates and local data caching.',
                getImage: () => (
                    <FcSynchronize
                        title="Optimization"
                        alt="Optimization"
                        style={{ width: '80%', height: '80%' }}
                    />
                ),
                color: '#ffdac1',
                subjects: ['Threading', 'Caching'],
            },
        ],
    },
];

export const contactItems = [
    {
        iconUrl: GmailIcon,
        urlPrefix: 'mailto:',
        url: 'pavlopouloschris2@gmail.com',
        scale: 0.6,
    },
    {
        iconUrl: GitHubIcon,
        url: 'github.com/chrispavlopoulos',
        scale: 0.6,
    },
    {
        iconUrl: LinkedInIcon,
        url: 'linkedin.com/in/chris-pavlopoulos',
        scale: 0.6,
    },
];
