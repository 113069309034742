import React, { Component } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ColorHelper } from '../helpers';

const fadeIn = keyframes` {
    0% { 
        opacity: 0; 
    }
    100% { 
        opacity: 1; 
    }
  }
`;

const randomIn = (offset, direction='right') => {
    let translate
    let translateHalf

    switch (direction) {
        case 'right':
            translate = `${offset}px, 0px`
            translateHalf = `-${offset / 2}px, 0px`
            break
        case 'left':
            translate = `-${offset}px, 0px`
            translateHalf = `${offset / 2}px, 0px`
            break
        case 'bottom':
            translate = `0px, -${offset}px`
            translateHalf = `0px, ${offset / 2}px`
            break
        case 'top':
            translate = `0px, ${offset}px`
            translateHalf = `0px, -${offset / 2}px`
            break
    }

    return (

        keyframes` {
            0% { 
                transform: translate(${translate});
            }

            100%  { 
                transform: translate(0px, 0px); 
            }
        }`
    )
}

const slideIn = (veritcalOffset) => keyframes` {
    0% { 
        transform: translate(0px, ${veritcalOffset}px); 
    }
    /* 50% {
        transform: translate(0px, -${veritcalOffset / 2}px); 
    } */
    100%  { 
        transform: translate(0px, 0px); 
    }
  }
`;

const float = (veritcalOffset) => keyframes` {
    0% { 
        transform: translate(0px, ${-veritcalOffset}px); 
    }
    30%  { 
        transform: translate(0px, 0px); 
    }
    100% { 
        transform: translate(0px, ${-veritcalOffset}px); 
    }
  }
`;

const AbsoluteWrapper = styled.div.attrs(props => ({
    style: {
        animationDelay: `${props.delay}s`,
        animationDuration: `${props.duration}s`,
    }
}))`
    animation: ${slideIn(12)},  ${fadeIn};
    animation-fill-mode: forwards;
    opacity: 0;
`

class Fly extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const children = this.props.children || {}
        const propStyle = this.props.style || {}
        const className = this.props.className || ''
        const { delay=1, duration=1 } = this.props

        return (
                <AbsoluteWrapper { ...{ delay, duration }} style={propStyle} className={className}>
                    {children}
                </AbsoluteWrapper>
        )
    }

}

export default Fly