import React, { Component } from 'react';
import styled from 'styled-components';
import FolioListItem from './FolioListItem';
import ItemHeader from './ItemHeader';
import Fly from './Fly';
import ColorText from './ColorText';
import CopyToClipboardButton from './CopyToClipboardButton';
import { colors, dimens, strings } from '../constants';

import {
    experiences,
    projects,
    contactItems,
} from '../constants/resumeData.js';
import CodeBackground from '../media/CodeBackground.jfif';
import { openPdf } from '../utils/fileUtils';
import Resume from '../docs/Christopher Pavlopoulos Resume.pdf';

const HeaderWrapper = styled.div`
    width: 100%;
    height: max(300px, 85vh);
    min-height: 85vh;
    display: flex;
    position: relative;
`;
const HeaderImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-drag: none;
    user-select: none;
`;

const HeaderImageCover = styled.div`
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    user-select: none;
`;

const HeaderTextWrapper = styled.div`
    position: absolute;
    z-index: 3;
    top: max(70px, 20vh);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const HeaderTitle = styled(ColorText)`
    font-size: max(20px, min(50px, 3vw));
    font-weight: bold;
    margin: 0;
    margin-bottom: ${dimens.paddingMedium + 'px'};

    @media (max-width: ${dimens.widthMediumDevice}px) {
        font-size: 42px;
    }
    @media (max-width: ${dimens.widthSmallDevice}px) {
        font-size: max(20px, min(42px, 7vw));
    }
`;

const HeaderSubtitle = styled(ColorText)`
    font-size: max(15px, min(40px, 2vw));
    color: ${colors.textColorSoft};
    margin: 0;
    margin-bottom: ${dimens.paddingSmall + 'px'};

    @media (max-width: ${dimens.widthMediumDevice}px) {
        font-size: 28px;
    }
    @media (max-width: ${dimens.widthSmallDevice}px) {
        font-size: max(12px, min(28px, 4vw));
    }
`;

const HeaderButtonContainer = styled(Fly)`
    display: flex;
    flex-direction: row;
    @media (max-width: ${dimens.widthSmallDevice}px) {
        flex-direction: column-reverse;
        margin-top: 5vh;
    }
`;

const HeaderButton = styled.button`
    border: 1.75px solid white;
    border-radius: 50px;
    overflow: hidden;
    background-color: transparent;
    padding: max(0px, min(18px, 1.1vw)) max(25px, min(43px, 1.1vw));
    margin-top: ${dimens.paddingXLarge}px;
    margin-left: ${(props) =>
        props.leftMargin ? 'max(30px, min(6vw, 100px))' : 'unset'};
    outline: 0;
    cursor: pointer;
    font: inherit;
    transition: background-color 0.1s ease-in-out;
    &:hover {
        background-color: ${colors.genericHighlight};
    }
    &:active {
        background-color: ${colors.genericPressed};
    }
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    font-size: max(10px, min(23px, 1.4vw));
    white-space: nowrap;

    @media (max-width: ${dimens.widthMediumDevice}px) {
        font-size: 20px;
        padding: 10px 12px;
        margin-left: ${(props) => (props.leftMargin ? '84px' : 'unset')};
    }
    @media (max-width: ${dimens.widthSmallDevice}px) {
        margin-left: 0;
    }
`;

const ExperienceWrapper = styled.div`
    flex: 1;
    color: ${colors.textColor};
    margin: ${dimens.paddingLarge}px;
    background-color: ${colors.background};
    max-width: 1500px;

    @media (max-width: ${dimens.widthSmallDevice}px) {
        width: unset;
    }
`;

const ExperienceItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ProjectsWrapper = styled.div`
    flex: 1;
    color: ${colors.textColor};
    padding: ${dimens.paddingLarge}px;
    background-color: ${colors.background};
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2));
    width: 100%;
    margin-right: 10%;

    @media (max-width: ${700}px) {
        width: unset;
        margin-right: 0;
    }
`;

const ProjectsItemContainer = styled.div`
    width: 90%;
    max-width: 1700px;
`;

const ContactMeWrapper = styled.div`
    flex: 1;
    color: ${colors.textColor};
    padding: ${dimens.paddingLarge}px;
    background-color: ${colors.background};
    width: 100%;
    min-height: calc(50vh - 24px);
    margin-right: 10%;

    @media (max-width: ${700}px) {
        width: unset;
        margin-right: 0;
    }
`;

const ContactMeItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${dimens.paddingSmall}px;
`;

const ContactMeItemIconWrapper = styled.a`
    width: 40px;
    height: 40px;
    margin-right: ${dimens.paddingMedium}px;
    flex-shrink: 0;
    user-drag: none;
    &::selection {
        background-color: transparent;
    }
`;

const ContactMeItemLink = styled.a`
    color: ${colors.accent};
    transition: filter 0.2s ease-in-out;
    &:hover {
        filter: brightness(0.9);
    }
    a:link {
        color: ${colors.accent};
    }
    a:visited {
        color: ${colors.accent};
    }
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-lines: 1;
    flex-shrink: 1;
    min-width: 0;
`;

const ContactMeCopyToClipboard = styled(CopyToClipboardButton)`
    margin-left: ${dimens.paddingMedium}px;
    @media (max-width: ${700}px) {
        margin-left: auto;
    }
`;

class HomePage extends Component {
    state = {
        hintShowing: false,
        colors: [], //['#FFE74C', '#FF5964', '#FFFFFF', '#38618C', '#35A7FF',],
        backgroundImageReady: false,
    };

    constructor(props) {
        super(props);

        this.headerWrapperRef = React.createRef();
        this.experienceWrapperRef = React.createRef();
        this.projectsWrapperRef = React.createRef();
        this.contactMeWrapperRef = React.createRef();
    }

    componentDidMount() {
        this.loadBackgroundImage();
    }

    loadBackgroundImage() {
        const img = new Image();
        img.onload = () => {
            // When the image finishes loading, update the component state
            this.setState({ backgroundImageReady: true });
        };
        // Setting the src triggers a browser download
        img.src = CodeBackground;
    }

    updateColors = (e) => {
        let text = e.target.value;

        const update = (value) => {
            let valArray = value.split(',');
            let result = [];
            for (let i = 0; i < valArray.length; i++) {
                if (valArray[i] === '') continue;

                result.push(valArray[i].trim());
            }

            this.setState({ colors: result }, () =>
                console.log(this.state.colors)
            );
        };
        if (!this.inputTimeout)
            this.inputTimeout = setTimeout(() => update(text), 500);
        else {
            clearTimeout(this.inputTimeout);
            this.inputTimeout = setTimeout(() => update(text), 500);
        }
    };

    getContrastPositions = () => {
        const {
            headerWrapperRef: headerRef,
            contactMeWrapperRef: contactMeRef,
        } = this;
        const headerRec = headerRef.current.getBoundingClientRect();

        return [headerRec.height, contactMeRef.current.offsetTop];
    };

    getPosition = (element) => {
        let ref = null;
        switch (element) {
            case strings.PAGE_EXPERIENCE:
                ref = this.experienceWrapperRef.current;
                break;
            case strings.PAGE_PROJECTS:
                ref = this.projectsWrapperRef.current;
                break;
            case strings.PAGE_CONTACT:
                ref = this.contactMeWrapperRef.current;
                break;
        }

        if (ref) {
            const rect = ref.getBoundingClientRect();
            return rect.y;
        }

        return 0;
    };

    highlight = (element) => {
        let ref = null;
        switch (element) {
            case strings.PAGE_EXPERIENCE:
                ref = this.experienceWrapperRef.current;
                break;
            case strings.PAGE_PROJECTS:
                ref = this.projectsWrapperRef.current;
                break;
            case strings.PAGE_CONTACT:
                ref = this.contactMeWrapperRef.current;
                break;
        }

        if (ref) {
            const header = ref.children[0];
            const highlightColor = colors.accentU1;
            const delay = 500;
            const delta = 650;
            header.style.transition = 'background-color 0.5s ease-in-out';
            setTimeout(() => {
                header.style.backgroundColor = highlightColor;
            }, delay);
            setTimeout(() => {
                header.style.backgroundColor = 'unset';
            }, delay + delta);
            // setTimeout(() => {
            //     header.style.backgroundColor = highlightColor
            // }, delay + delta * 3)
            // setTimeout(() => {
            //     header.style.backgroundColor = 'unset'
            // }, delay + delta * 4)
        }
    };

    onContactMeClicked = () => {
        if (this.props.onContactMeClicked) this.props.onContactMeClicked();
    };

    render() {
        const { isMinified = false } = this.props;

        return (
            <>
                <HeaderWrapper ref={this.headerWrapperRef}>
                    <HeaderImage
                        src={
                            this.state.backgroundImageReady
                                ? CodeBackground
                                : null
                        }
                    />
                    <HeaderImageCover />
                    <HeaderTextWrapper
                        onMouseOver={() => {
                            if (!this.state.hintShowing)
                                this.setState({ hintShowing: true });
                        }}
                    >
                        <HeaderTitle
                            startDelay={1}
                            animationOffset={10}
                            colors={this.state.colors}
                        >
                            Christopher Pavlopoulos
                        </HeaderTitle>
                        <HeaderSubtitle
                            startDelay={1}
                            animationOffset={10}
                            colors={this.state.colors}
                        >
                            Professional Full Stack Software Developer
                        </HeaderSubtitle>
                        <HeaderButtonContainer>
                            <HeaderButton
                                title="Download a PDF of My Resume"
                                onClick={() => openPdf(Resume)}
                            >
                                <ColorText
                                    animate={false}
                                    colors={this.state.colors}
                                >
                                    Resume
                                </ColorText>
                            </HeaderButton>
                            <HeaderButton
                                leftMargin
                                title="Contact Me"
                                onClick={this.onContactMeClicked}
                            >
                                <ColorText
                                    animate={false}
                                    colors={this.state.colors}
                                >
                                    Contact Me
                                </ColorText>
                            </HeaderButton>
                        </HeaderButtonContainer>
                    </HeaderTextWrapper>
                    {/* <GradientInputWrapper>
                        <GradientInput onChange={this.updateColors} />
                    </GradientInputWrapper> */}
                </HeaderWrapper>
                <ExperienceWrapper ref={this.experienceWrapperRef}>
                    <ItemHeader
                        id="header"
                        text={'Experience'}
                        style={{ marginBottom: dimens.paddingLarge + 'px' }}
                    />
                    <ExperienceItemWrapper>
                        {experiences.map((experience, index) => {
                            return (
                                <FolioListItem
                                    key={experience.title + '' + index}
                                    data={experience}
                                    imageZoom={'100%'}
                                />
                            );
                        })}
                    </ExperienceItemWrapper>
                </ExperienceWrapper>
                <ProjectsWrapper ref={this.projectsWrapperRef}>
                    <ItemHeader
                        text={'Projects'}
                        style={{ marginBottom: dimens.paddingLarge + 'px' }}
                    />
                    <ProjectsItemContainer>
                        {projects.map((project, index) => {
                            return (
                                <FolioListItem
                                    key={project.title + '' + index}
                                    data={project}
                                    imageZoom={'100%'}
                                />
                            );
                        })}
                    </ProjectsItemContainer>
                </ProjectsWrapper>
                <ContactMeWrapper ref={this.contactMeWrapperRef}>
                    <ItemHeader
                        text={'Contact Me'}
                        style={{ marginBottom: dimens.paddingLarge + 'px' }}
                    />
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {contactItems.map((item, index) => {
                            const {
                                icon,
                                iconUrl,
                                url,
                                scale = 1,
                                urlPrefix = '//',
                            } = item;

                            return (
                                <ContactMeItem key={'contactme_link_' + index}>
                                    {icon ||
                                        (iconUrl && (
                                            <ContactMeItemIconWrapper
                                                target="_blank"
                                                href={`${urlPrefix}${url}`}
                                                style={{
                                                    transform: `scale(${scale})`,
                                                }}
                                            >
                                                {iconUrl ? (
                                                    <img
                                                        src={iconUrl}
                                                        draggable="false"
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                    />
                                                ) : (
                                                    icon
                                                )}
                                            </ContactMeItemIconWrapper>
                                        ))}
                                    <ContactMeItemLink
                                        target="_blank"
                                        href={`${urlPrefix}${url}`}
                                    >
                                        {url}
                                    </ContactMeItemLink>
                                    <ContactMeCopyToClipboard
                                        text={
                                            urlPrefix === '//'
                                                ? `https://www.${url}`
                                                : url
                                        }
                                    />
                                </ContactMeItem>
                            );
                        })}
                    </div>
                </ContactMeWrapper>
            </>
        );
    }
}

export default HomePage;
