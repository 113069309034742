import React, { Component } from 'react'
import styled from 'styled-components'
import { colors, dimens } from '../constants'
import ContentCard2 from './ContentCard2'
import ColorText from './ColorText'
import FolioLink from './FolioLink'

const ItemWrapper = styled.div`
    min-height: 150px;
    /* padding: ${dimens.paddingMedium}px; */
    margin-bottom: ${dimens.paddingLarge}px;
`

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const HeaderTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const TitleTextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const TitleText = styled.h4`
    font-size: 24px;
    margin: 0;
`

const SubtitleText = styled(ColorText)`
    font-size: 20px;
    margin: 0;
    color: ${colors.textColorSoft};
    filter: brightness(0.8);
`

const TitleImageContainer = styled.div`
    width: 55px;
    height: 55px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: ${dimens.paddingMedium}px;
`

const TitleImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
`

const ItemDate = styled.h5`
    font-size: 16px;
    color: ${colors.textColorSofterVariant};
    margin: 0;
    margin-top: ${dimens.paddingSmall}px;
    font-weight: 300;
`

const DescriptionWrapper = styled.div`
    width: 60%;
    color: ${colors.textColorSoft};
    margin-top: ${dimens.paddingMedium}px;
    margin-bottom: ${dimens.paddingMedium}px;

    @media (max-width: ${dimens.widthSmallDevice}px) {
        width: 100%;
    }
`

const BulletsWrapper = styled.div`
    margin-top: ${dimens.paddingXLarge}px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const CardWrapper = styled.div`
    margin-bottom: ${dimens.paddingSmall}px;
    width: calc(50%);
    
    @media (max-width: ${dimens.widthSmallDevice}px) {
        width: 100%;
    }
`

const BulletItem = styled.li`
    font-size: 18px;
    margin-bottom: ${dimens.paddingSmall}px;
`

class FolioListItem extends Component {

    render() {
        const { data } = this.props
        const imageZoom = this.props.imageZoom || '100%';
        const titleComp = data.titleLink ? (
            <TitleTextWrapper >
                <TitleText>{data.title}</TitleText>
                <span style={{ marginLeft: dimens.paddingMedium, marginBottom: '2px', fontSize: '12px', alignSelf: 'flex-end' }}>(<FolioLink href={`${data.titleLink}`} >GitHub</FolioLink>)</span>
            </TitleTextWrapper>
        ) : (
            <TitleText>{data.title}</TitleText>
        )
        const descriptionComp = data.description ? (
            <DescriptionWrapper>{data.description}</DescriptionWrapper>
        ) : null

        return (
            <div style={{overflow: 'hidden', width: '100%'}}>
                <ItemWrapper>
                    <HeaderWrapper>
                        {data.image && 
                            <TitleImageContainer>
                                <TitleImage src={data.image} style={{ width: imageZoom, height: imageZoom }} />
                            </TitleImageContainer>
                        }
                        <HeaderTextWrapper>
                            {titleComp}
                            <SubtitleText animate={false} highlight={'rgba(0,0,0,0.1)'}>{data.subtitle}</SubtitleText>
                        </HeaderTextWrapper>
                    </HeaderWrapper>
                    <ItemDate>{data.dateRange}</ItemDate>
                    {descriptionComp}
                    <BulletsWrapper>
                        {data.bullets.map((bullet, index) => {
                            return (
                                <CardWrapper key={'bullet' + index}>
                                    <ContentCard2 data={bullet} />
                                </CardWrapper>
                            )
                        })}
                    </BulletsWrapper>
                </ItemWrapper>
            </div>
        )
    }
}

export default FolioListItem