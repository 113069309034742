import React, { Component } from 'react'
import snap from 'snapsvg-cjs'
import styled from 'styled-components'
import { colors } from '../constants'

const COLOR_DRAWER_OPEN = colors.drawerTextColor;

const SVGWrapper = styled.div`

`

const shapes = [
    {
        d: "M 3 6 L 3 8 L 21 8 L 21 6 L 3 6 Z M 3 18 L 9 18 L 9 16 L 3 16 L 3 18 Z M 3 13 L 15 13 L 15 11 L 3 11 L 3 13 Z"
    },
    {
        d: "M 3 6 L 3 8 L 21 8 L 21 6 L 3 6 Z M 3 18 L 21 18 L 21 16 L 3 16 L 3 18 Z M 3 13 L 21 13 L 21 11 L 3 11 L 3 13 Z"
    }
]

class Hamburger extends Component {

    state = {
        iconColor: 'white',
        isColorInverted: false,
    }

    
    componentDidMount() {
        let root = snap.select('#hamburger_svg')
        this.snapPath = root.select('#hamburger_path')
    }

    setOpen = (isOpen) => {
        if(this.snapPath)
            this.snapPath.animate({d: isOpen ? shapes[0].d : shapes[1].d}, 250, mina.easeIn )

        this.isOpen = isOpen
    }

    setColor = (color) => {
        if (this.state.iconColor !== color)
            this.setState({ iconColor: color })
    }

    setInverted = (isColorInverted) => {
        if(isColorInverted !== this.state.isColorInverted)
            this.setState({ isColorInverted })
    }

    render() {
        const {style: propStyles = {}, isOpen = false, isMinified = false} = this.props
        let { isColorInverted } = this.state
        let iconColor = isColorInverted ? 'black' : 'white';

        if (isOpen !== this.isOpen) {
            this.setOpen(isOpen)
        }

        if (isOpen)
          iconColor = COLOR_DRAWER_OPEN;

        return (
            <SVGWrapper >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    id="vector"
                    width="24px"
                    height="24px"
                    style={{ color: iconColor, ...propStyles }}
                    className="morph-path1"
                    id="hamburger_svg"
                >
                    <path
                        d={shapes[0].d}
                        fill="currentColor"
                        strokeWidth="1"
                        id="hamburger_path"
                        />
                </svg>
            </SVGWrapper>
        )
    }
}

export default Hamburger