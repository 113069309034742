import React from 'react';
import styled from 'styled-components'
import { dimens, colors } from '../constants'
import { MdContentCopy } from 'react-icons/md'

const CopyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border-radius: 10%;
    padding: ${dimens.paddingSmall}px;
    transition: background-color 0.2s ease-in-out;
    &:hover {
        background-color: ${colors.genericHighlightInverted};
    }
    &:active {
        background-color: ${colors.genericPressedInverted};
    }
`

const CopyIcon = styled(MdContentCopy).attrs(props => ({
    draggable: 'false',
}))`
    flex-shrink: 0;
    color: ${colors.textColorSoft};
    width: 12px;
    height: 12px;
    pointer-events: none;
`

const CopyText = styled.span`
    color: ${colors.textColorSoft};
    font-size: 12px;
    margin-left: ${dimens.paddingSmall}px;
    flex-shrink: 0;
    white-space: nowrap;
    pointer-events: none;
`


class CopyToClipboardButton extends React.Component {

    state = {
        clicked: false,
    }

    copyToClipboard = (text) => {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);

        this.setState({ clicked: true })
        return result;
    }

    onMouseEnter = (e) => {
        console.log('in');
        if(this.mouseOutTimeOut) {
            window.clearTimeout(this.mouseOutTimeOut)
        }

    }

    onMouseOut = (e) => {
        if(this.mouseOutTimeOut) {
            window.clearTimeout(this.mouseOutTimeOut)
        }

        if(this.state.clicked) {
            this.mouseOutTimeOut = setTimeout(() => {
                this.setState({clicked: false})
            }, 1000)
        }
    }

    render () {
        const { text, style={}, className='' } = this.props
        const { clicked } = this.state

        return (
            <CopyWrapper
                onClick={() => this.copyToClipboard(text)}
                onMouseEnter={this.onMouseEnter}
                onMouseOut={this.onMouseOut}
                style={{...style}}
                className={className}
            >
                <CopyIcon 
                    color={'inherit'} 
                />
                <CopyText>{clicked ? 'Copied ✓' : 'Copy'}</CopyText>
            </CopyWrapper>
        )
    }
}

export default CopyToClipboardButton