import React from 'react'
import styled from 'styled-components'
import { dimens, colors } from '../constants'

const ScFolioLink = styled.a`
    color: ${colors.accent};
    transition: filter 0.2s ease-in-out;
    &:hover {
        filter: brightness(0.9);
    }
    a:link {
        color: ${colors.accent};
    }
    a:visited {
        color: ${colors.accent};
    }
`

class FolioLink extends React.Component {
    render() {
        const { children=[] } = this.props
        let href = this.props.href
        if (!( href.startsWith('https://') || href.startsWith('http://') ))
            href = `//${href}`
        
        return (
            <ScFolioLink target="_blank" {...this.props} href={href} >
                {children}
            </ScFolioLink>
        )
    }
}

export default FolioLink