import React, { Component} from "react";
import styled from 'styled-components';
import Drawer from './components/Drawer'
import { IconContext } from 'react-icons'
import { colors, dimens, strings } from './constants'
import HomePage from "./components/HomePage";
import Hamburger from './components/Hamburger'
import { openPdf } from './utils/fileUtils';
import Resume from './docs/Christopher Pavlopoulos Resume.pdf';

const LS_PAGE_SCROLL = 'page-scroll'
const minifiedWidth = dimens.widthMediumDevice

const HamburgerWrapper = styled.div`
    position: fixed;
    z-index: 30;
    width: 5vh;
    height: 5vh;
    min-width: 24px;
    min-height: 24px;
    padding-top: ${dimens.paddingLarge}px;
    cursor: pointer;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    transition: padding-left 0.2s ease-in-out;
    /* &:active {
        background-color: ${colors.genericHighlight};
    } */
`
const HamburgerIcon = styled(Hamburger)`
    position: relative;
    width: 5vh;
    height: 5vh;
    max-width: 48px;
    max-height: 48px;
    min-width: 24px;
    min-height: 24px;
    &:hover {
        transform: scale(1.1);
    }
`

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    overflow-x: hidden;
`

class App extends Component {

    state = {
        currentPage: strings.PAGE_HOME,

        drawerOpen: true,
        isHamburgerColorInverted: false,
        isMinified: false,
    }

    constructor(){
        super();

        this.isHamburgerColorInverted = false
        this.currentPageRef = React.createRef()
        this.pageWrapper = React.createRef()
        this.drawerRef = React.createRef()
        this.hamburgerIconRef = React.createRef()
    }

    onResize = (e) => {
        if (window.innerWidth <= minifiedWidth) {
            if (!this.state.isMinified) {
                this.setState({ isMinified: true })
                this.onPageScrolled(this.pageWrapper.current.scrollTop)
            }
        } else {
            if (this.state.isMinified) {
                this.setState({ isMinified: false })
                this.onPageScrolled(this.pageWrapper.current.scrollTop)
            }
        }
    }

    onBeforeUnload = (e) => {
        if (this.pageWrapper && this.pageWrapper.current)
            localStorage.setItem(LS_PAGE_SCROLL, this.pageWrapper && this.pageWrapper.current.scrollTop);
    }

    componentDidMount() {
        //  Restore saved scroll position if one exists
        if (this.pageWrapper && this.pageWrapper.current) {
            let savedScrollPosition = localStorage.getItem(LS_PAGE_SCROLL);
            if (savedScrollPosition)
                this.pageWrapper.current.scrollTo({top: savedScrollPosition, left: 0})
        }

        this.setState({ 
            contrastPositions: this.currentPageRef.current.getContrastPositions(),
            isMinified: window.innerWidth <= minifiedWidth,
            drawerOpen: window.innerWidth > minifiedWidth,
        })

        window.addEventListener('resize', this.onResize)
        window.addEventListener("beforeunload", this.onBeforeUnload)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    onPageScrolled = (scrollY) => {
        const { isHamburgerColorInverted } = this
        const { drawerOpen, isMinified } = this.state
        const contrastPositions = this.currentPageRef.current.getContrastPositions()

        if (!isMinified || drawerOpen) {
            if (isHamburgerColorInverted) {
                this.hamburgerIconRef.current.setInverted(false)
                this.isHamburgerColorInverted = false
            }

            return
        }

        let contrastPosition = contrastPositions[0] - (24 * 2)


        if (scrollY > contrastPosition && !isHamburgerColorInverted) {
            this.hamburgerIconRef.current.setInverted(true)
            this.isHamburgerColorInverted = true
        } else if (scrollY <= contrastPosition && isHamburgerColorInverted) {
            this.hamburgerIconRef.current.setInverted(false)
            this.isHamburgerColorInverted = false
        }
    }

    handleMobileDrawerClick = (e) => {
        const { drawerOpen, isMinified } = this.state

        if (!drawerOpen || !isMinified)
            return

        const drawerWidth = this.drawerRef.current.getWidth()
        if(e.nativeEvent.clientX > drawerWidth) {
            this.setState({ drawerOpen: false }, () => {
                // This should ensure our Hamburger is the correct color when the drawer is closed
                this.onPageScrolled(this.pageWrapper ? this.pageWrapper.current.scrollTop : 0);
            })
        }
        
    }

    onDrawerItemClicked = (index, item) => {
        const { name } = item
        
        switch (name) {
            case strings.PAGE_HOME:
                this.pageWrapper.current.scrollTo({top: 0, left: 0, behavior: 'smooth' })
            break
            case strings.PAGE_EXPERIENCE:
            case strings.PAGE_PROJECTS:
            case strings.PAGE_CONTACT:
                if (this.state.currentPage !== strings.PAGE_HOME) {
                    //TODO change page
                }

                const scrollY = this.currentPageRef.current.getPosition(name)
                this.currentPageRef.current.highlight(name)
                this.pageWrapper.current.scrollBy({top: scrollY, left: 0, behavior: 'smooth' })
            break;
            case strings.PAGE_RESUME:
                openPdf(Resume);
            break;
        }
    }

    onDrawerButtonClicked = (e) => {
        const { drawerOpen: drawerCurrentlyOpen, isMinified } = this.state
        let drawerWillBeOpen = !drawerCurrentlyOpen

        if (drawerWillBeOpen && this.isHamburgerColorInverted) {
            this.isHamburgerColorInverted = false
            this.hamburgerIconRef.current.setInverted(false)
            this.setState({ drawerOpen: drawerWillBeOpen })
        } else if (!drawerWillBeOpen && !this.isHamburgerColorInverted) {
            this.setState({ drawerOpen: drawerWillBeOpen }, () => {
                this.onPageScrolled(this.pageWrapper.current.scrollTop)
            })
        } else {
            this.setState({ drawerOpen: drawerWillBeOpen });
        }

    }

    render() {
        const { drawerOpen, isHamburgerColorInverted, isMinified } = this.state
        const hamburgerColor = drawerOpen ? 'white' : isHamburgerColorInverted ? 'black' : 'white'

        const hamburgerPadding = drawerOpen ? `${dimens.paddingLarge}px` : `max(${dimens.paddingLarge}px, min(39px, 2.25vw))`

        return(
            <IconContext.Provider value={{ color: 'white', verticalAlign: 'center' }} >
                <div style={StyleSheet.rootStyles} onMouseDown={this.handleMobileDrawerClick}>
                    <HamburgerWrapper onMouseDown={this.onDrawerButtonClicked} style={{ paddingLeft: hamburgerPadding }}>
                        <HamburgerIcon ref={this.hamburgerIconRef} isOpen={drawerOpen} style={{ transition: 'transform 0.2s ease-in-out, color 0.5s ease-in-out', filter: `drop-shadow(0px 3px 3px rgba(0,0,0,0.2))` }}/>
                    </HamburgerWrapper>

                    <Drawer 
                        ref={this.drawerRef} 
                        isOpen={drawerOpen} 
                        isMinified={isMinified} 
                        onItemClicked={this.onDrawerItemClicked}
                    />
                    <PageWrapper 
                        ref={this.pageWrapper}
                        id="page_wrapper"
                        onScroll={(e) => {
                            if (e.nativeEvent && e.nativeEvent.target && e.nativeEvent.target.id === 'page_wrapper')
                                this.onPageScrolled(e.nativeEvent.target.scrollTop)
                        }}
                    >
                        <HomePage ref={this.currentPageRef} isMinified={isMinified} onContactMeClicked={() => this.onDrawerItemClicked(-1, {name: strings.PAGE_CONTACT})} />
                    </PageWrapper>
                </div>
            </IconContext.Provider>
        )
    }

}

const StyleSheet = {
    rootStyles: {
        color: colors.textColor,
        backgroundColor: colors.background,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
    }
}

export default App;