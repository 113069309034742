import { ColorHelper } from './helpers';

const COLOR_LIGHT_FILL = 'rgb(255, 255, 255)';
const COLOR_DARK_FILL = 'rgb(40, 50, 70)';

const colorWithAlpha = (color, alpha) => {
    return color.replace('rgb', 'rgba').replace(')', `, ${alpha})`);
};

const ThemeLight = {
    genericHighlight: colorWithAlpha(COLOR_LIGHT_FILL, 0.05),
    genericPressed: colorWithAlpha(COLOR_LIGHT_FILL, 0.1),
    genericHighlightInverted: 'rgba(0, 0, 0, 0.05)',
    genericPressedInverted: 'rgba(0, 0, 0, 0.1)',
    accentU1: 'hsla(355, 78%, 56%, 0.1)',
    accent: 'hsl(355, 78%, 56%)',
    background: COLOR_LIGHT_FILL,
    backgroundInverse: COLOR_DARK_FILL,
    backgroundSoft: 'rgb(255, 255, 255)',
    drawer: '#2C394B',
    drawerTextColor: 'white',
    textColor: 'rgb(50, 50, 70)',
    textColorInverted: 'rgb(255, 255, 255)',
    textColorSoft: 'rgb(120, 120, 130)',
    textColorSofter: 'rgb(150, 150, 160)',
    textColorSoftVariant: 'rgb(120, 120, 140)',
    textColorSofterVariant: 'rgb(190, 190, 205)',

    cardBorder: 'rgba(100, 100, 100, 0.2)',
};

const ThemeDark = {
    genericHighlight: 'rgba(0, 0, 0, 0.1)',
    genericPressed: 'rgba(0, 0, 0, 0.2)',
    accent: '#ff5d5f',
    background: COLOR_DARK_FILL,
    drawer: 'rgb(70, 150, 200)',
    drawerGradient:
        'linear-gradient(141deg, rgb(109, 164, 153) 0%, rgb(1, 160, 179) 51%, rgb(14, 141, 192) 75%)',
    textColor: 'rgb(235, 250, 250)',
    textColorInverted: COLOR_LIGHT_FILL,
    textColorSoft: 'rgb(200, 220, 220)',
    textColorSoftVariant: 'rgb(120, 120, 140)',
    textColorSofterVariant: 'rgb(190, 190, 210)',

    cardBorder: 'rgba(255, 255, 255, 0.4)',
};

const colors = {
    ...ThemeLight,
    // ...ThemeDark,
};

const shiftRGB = (inputRGB, shiftValues) => {
    for (let i = 0; i < inputRGB.length; i++) {
        inputRGB[i] = parseInt(inputRGB[i]) + shiftValues[i];
    }
};

const makeHighlight = (fromColor, negative = false) => {
    let numbers = ColorHelper.arrayFromColor(fromColor);

    let shiftValue = negative ? -20 : 20;
    shiftRGB(numbers, [shiftValue, shiftValue, shiftValue]);

    return ColorHelper.arrayToColor(numbers);
};

const makePressed = (fromColor, negative = false) => {
    let numbers = ColorHelper.arrayFromColor(fromColor);

    let shiftValue = negative ? -40 : 40;
    shiftRGB(numbers, [shiftValue, shiftValue, shiftValue]);

    return ColorHelper.arrayToColor(numbers);
};

colors.backgroundHighlight = makeHighlight(colors.background, true);
colors.drawerHighlight = makeHighlight(colors.drawer);
colors.drawerPressed = makePressed(colors.drawer);

const dimens = {
    paddingSmall: 6,
    paddingMedium: 12,
    paddingMarge: 16,
    paddingLarge: 24,
    paddingXLarge: 32,

    cardRadius: 6,

    widthSmallDevice: 700,
    widthMediumDevice: 1400,
    widthLargeDevice: 1800,
};

const strings = {
    PAGE_HOME: 'Home',
    PAGE_EXPERIENCE: 'Experience',
    PAGE_PROJECTS: 'Projects',
    PAGE_CONTACT: 'Contact',
    PAGE_RESUME: 'Resume',
};

export { colors, dimens, strings };
